// Copyright (C) 2021-2022 Intel Corporation
// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

const validationPatterns = {
    validatePasswordLength: {
        pattern: /(?=.{8,})/,
        message: 'Пароль должен содержать не менее 8 символов',
    },

    passwordContainsNumericCharacters: {
        pattern: /(?=.*[0-9])/,
        message: 'Пароль должен содержать хотя бы 1 цифру',
    },

    passwordContainsUpperCaseCharacter: {
        pattern: /(?=.*[A-Z])/,
        message: 'Пароль должен содержать хотя бы 1 заглавную букву алфавита',
    },

    passwordContainsLowerCaseCharacter: {
        pattern: /(?=.*[a-z])/,
        message: 'Пароль должен содержать хотя бы 1 строчную букву алфавита',
    },

    validateUsernameLength: {
        pattern: /(?=.{5,})/,
        message: 'Имя пользователя должно содержать не менее 5 символов',
    },

    validateUsernameCharacters: {
        pattern: /^[a-zA-Z0-9_\-.]{5,}$/,
        message: 'Достуаны только эти символы: (a-z), (A-Z), (0-9), -, _, .',
    },

    /*
        \p{Pd} - dash connectors
        \p{Pc} - connector punctuations
        \p{Cf} - invisible formatting indicator
        \p{L} - any alphabetic character
        Useful links:
        https://stackoverflow.com/questions/4323386/multi-language-input-validation-with-utf-8-encoding
        https://stackoverflow.com/questions/280712/javascript-unicode-regexes
        https://stackoverflow.com/questions/6377407/how-to-validate-both-chinese-unicode-and-english-name
    */
    validateName: {
        // eslint-disable-next-line
        pattern: /^(\p{L}|\p{Pd}|\p{Cf}|\p{Pc}|['\s]){2,}$/gu,
        message: 'Неверное имя',
    },

    validateAttributeName: {
        pattern: /\S+/,
        message: 'Неверное имя',
    },

    validateLabelName: {
        pattern: /\S+/,
        message: 'Неверное имя',
    },

    validateAttributeValue: {
        pattern: /\S+/,
        message: 'Неверное значение атрибута',
    },

    validateURL: {
        // eslint-disable-next-line
        pattern: /^(https?:\/\/)[^\s$.?#].[^\s]*$/, // url, ip
        message: 'URL-адрес недействителен',
    },

    validateOrganizationSlug: {
        pattern: /^[a-zA-Z\d]+$/,
        message: 'Разрешены только латинские символы и цифры.',
    },

    validatePhoneNumber: {
        pattern: /^[+]*[-\s0-9]*$/g,
        message: 'Введенный номер телефона неверен',
    },
};

export default validationPatterns;
